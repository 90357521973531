import React, { Component } from 'react';


import * as am4core from '@amcharts/amcharts4/core';
// import * as am4charts from '@amcharts/amcharts4/charts';
import '@amcharts/amcharts4/charts';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4editor from '@amcharts/editor4';
import {BrowserRouter as Router,Link} from "react-router-dom";

am4core.useTheme(am4themes_animated);

class Dechets extends Component <any, any> {
  
  private chart?: am4core.Sprite;
  private launcher?: am4editor.EditorLauncher;
  private chartConfiguration : object;

  

  constructor(props: any) {
    super(props);
    // this.editChart = this.editChart.bind(this);
    this.state = {
      listedechets: []
  };
  this.getDataDechets = this.getDataDechets.bind(this);
  }

  getDataDechets(){
    fetch('https://data-api.pevelecarembault.fr/dechetterie_mois')
    .then(res => {
      if(res.ok) return res.json();
      throw new Error ('Request failed');
    })
    .then(data => {
      this.setState({listedechets : data})
    })
    .catch(error => {
      console.log(error);
    })
  }

  componentDidMount() {
    this.getDataDechets()
    console.log(this.state.listedechets)

  }

  configGraph(){
    this.chartConfiguration = {
      "type": "XYChart",
      "data": this.state.listedechets,
      "xAxes": [{
          "type": "CategoryAxis",
          "dataFields": {
              "category": "mois"
          },
          "renderer": {
              "labels": {
							    "template": {
								    "type": "AxisLabel",
								    "rotation": 45
						      }
					    },
              "grid": {
                  "template": {
                      "type": "Grid",
                      "location": 0
                  }
              },
              "minGridDistance": 20
          }
      }],
      "yAxes": [{
          "type": "ValueAxis",
          "min": 0,
          "renderer": {
              "maxLabelPosition": 0.98
          }
      }],
      "series": [{
          "type": "ColumnSeries",
          "columns": {
              "template": {
                  "type": "Column",
                  "strokeOpacity": 0,
                  "tooltipText": "{categoryX}\n{valueY}",
                  "tooltipPosition": "pointer"
              }
          },
          "dataFields": {
              "valueY": "tonnage",
              "categoryX": "mois"
          },
          "fill": "#00A86A",
          "stroke": "#00A86A",
          "fontFamily": "HELVETICA",
          "fontSize": 12,
          "fontWeight": "bold",
          "numberFormatter": {
              "type": "NumberFormatter",
              "forceCreate": true,
              "numberFormat": "#  tonnes",
              "intlLocales": "fr_FR"
          },
          "sequencedInterpolation": true,
          "sequencedInterpolationDelay": 100
      }],
      "titles": {
          "values": [{
              "type": "Label",
              "id": "titre",
              "text": "Tonnage collecté par mois en 2021",
              "paddingBottom": 15,
              "fontFamily": "HELVETICA",
              "fontSize": 20,
              "fontWeight": "bold"
          }],
          "template": {
              "type": "Label",
              "text": "Tonnage collecté par mois en 2021"
          }
      },
      "numberFormatter": {
          "type": "NumberFormatter",
          "forceCreate": true,
          "numberFormat": "# ",
          "intlLocales": "fr"
      },
      "exporting": {
        "menu": {
        "align": "left",
         "verticalAlign": "top"
     }
    }

      };
      return this.chartConfiguration

    }

 componentWillUnmount() {
   if (this.chart) {
     this.chart.dispose();
   }
  }


  renderChart() {
     if (this.chart) {
      this.chart.dispose();
     }
    this.chart = am4core.createFromConfig((this.configGraph()), 
     'chartdiv'
    );
  }



  render() {
    this.renderChart();
    return (
      <div>
        <h1>Chiffres clefs Pévèle Carembault</h1>
        <ul>
        <li>
            <Link to="/">Tonnage collecté par type de déchets</Link>
          </li>
          <li>
            <Link to="/graph1">Poids des déchets collectés par habitant </Link>
          </li>
          <li>
            <Link to="/graph2">Tonnage collecté par mois</Link>
          </li>
        </ul>
        <h2> Collecte en porte à porte: </h2>
         <ul>
            <li>
             <Link to="/graph3">Tonnage collecté par type de déchets</Link>
            </li>
           <li>
             <Link to="/graph4">Poids des déchets collectés par habitant </Link>
            </li>
        	</ul> 

        <div id="chartdiv" style={{ width: '800px', height: '600px', margin: '20px auto'}}></div>
         {/* <button onClick={this.editChart}>edit chart</button>  */}
      </div>
    );
  }
}

export default Dechets;


