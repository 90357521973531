import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import DechetsThumeries from './components/DechetsThumeries/DechetsThumeries';
// import Dechets from './components/Dechets/Dechets'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
     <App /> 
  </BrowserRouter>
);


reportWebVitals();
