import React, { Component } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import '@amcharts/amcharts4/charts';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4editor from '@amcharts/editor4';
import {BrowserRouter as Router,Link} from "react-router-dom";


am4core.useTheme(am4themes_animated);

class DechetsPap_tot extends Component <any, any> {
  
  private chart?: am4core.Sprite;
  private launcher?: am4editor.EditorLauncher;
  private chartConfiguration : object;


  

  constructor(props: any) {
    super(props);
    // this.editChart = this.editChart.bind(this);
    this.state = {
      listedechets: []
  };
  this.getDataDechets = this.getDataDechets.bind(this);
  }

  getDataDechets(){
    fetch('https://data-api.pevelecarembault.fr/pap_tonnage')
    .then(res => {
      if(res.ok) return res.json();
      throw new Error ('Request failed');
    })
    .then(data => {
      this.setState({listedechets : data})
    })
    .catch(error => {
      console.log(error);
    })
  }

  componentDidMount() {
    this.getDataDechets()
    console.log(this.state.listedechets)

  }


    configGraph(){
      let chart = am4core.create("chartdiv", am4charts.RadarChart);
      chart.data = this.state.listedechets;

      chart.dataSource.parser = new am4core.JSONParser();
      let title = chart.titles.create();
      title.text = "Tonnage par flux de déchets";
      title.fontFamily = "HELVETICA";
      title.fontSize = 20;
      title.marginBottom = 30;

     // Make chart not full circle
    chart.startAngle = -90;
    chart.endAngle = 180;
    chart.innerRadius = am4core.percent(20);
  
  // Set number format
  chart.numberFormatter.numberFormat = "#.#";
  
  // Create axes
  let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererRadial>());
  categoryAxis.dataFields.category = "type";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.grid.template.strokeOpacity = 0;
  categoryAxis.renderer.labels.template.horizontalCenter = "right";
  categoryAxis.renderer.labels.template.fontWeight = "500";
  categoryAxis.renderer.labels.template.adapter.add("fill", function(fill, target) {
    return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
  });
  categoryAxis.renderer.minGridDistance = 10;
  
  let valueAxis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());

  valueAxis.renderer.grid.template.strokeOpacity = 0;
  valueAxis.min = 0;
  valueAxis.max = 100;
  valueAxis.strictMinMax = true;
  
  // Create series
  let series1 = chart.series.push(new am4charts.RadarColumnSeries());
  series1.dataFields.valueX = "full";
  series1.dataFields.categoryY = "type";
  series1.clustered = false;
  series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
  series1.columns.template.fillOpacity = 0.08;
  //series1.columns.template.cornerRadiusTopLeft = 20;
  series1.columns.template.strokeWidth = 0;
  series1.columns.template.radarColumn.cornerRadius = 20;
  
  let series2 = chart.series.push(new am4charts.RadarColumnSeries());
  series2.dataFields.valueX = "part_dech";
  series2.dataFields.categoryY = "type";
  series2.clustered = false;
  series2.columns.template.strokeWidth = 0;
  series2.columns.template.tooltipText = "{type}:{part_dech} % = [bold]{tot_dechet} [/]tonnes";
  series2.columns.template.radarColumn.cornerRadius = 20;
  series2.calculatePercent = true;
  series2.columns.template.adapter.add("fill", function(fill, target) {
    return chart.colors.getIndex(target.dataItem.index);
  });
  
  // Add cursor
  chart.cursor = new am4charts.RadarCursor();
  chart.legend = new am4charts.Legend();

series2.events.on("dataitemsvalidated", function() {
  var data = [];
  series2.dataItems.each(function(dataItem) {
    data.push({ name: dataItem.categoryY, fill: dataItem.column.fill, seriesDataItem: dataItem })
  })

  chart.legend.data = data;
  chart.legend.itemContainers.template.events.on("toggled", function(event) {
  //   var seriesDataItem = event.target.dataItem.dataContext.seriesDataItem;
  //   if (event.target.isActive) {
  //     seriesDataItem.hide(series2.interpolationDuration, 0, 0, ["valueX"]);
  //   }
  //   else {
  //     seriesDataItem.show(series2.interpolationDuration, 0, ["valueX"]);
  //   }
  // });
  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = "left";
  chart.exporting.menu.verticalAlign = "bottom";
})
    })}
    
     componentWillUnmount() {
       if (this.chart) {
         this.chart.dispose();
       }
      }
    
    
      renderChart() {
         if (this.chart) {
          this.chart.dispose();
         }
         this. configGraph();
        // this.chart = am4core.createFromConfig((this.configGraph()), 
        //  'chartdiv'
        // );
      }
    
    
      render() {
        this.renderChart();
        return (
          <div id="tb">
           <h1>Chiffres clefs Pévéle Carembault</h1>
            <h2> Déchetteries: </h2>
             <ul>
             <li>
                <Link to="/">Tonnage collecté par type de déchets</Link>
              </li>
              <li>
                <Link to="/graph1">Poids des déchets collectés par habitant </Link>
              </li>
              <li>
                <Link to="/graph2">Tonnage collecté par mois</Link>
              </li>
      </ul>         
      <h2> Collecte en porte à porte: </h2>
         <ul>
            <li>
             <Link to="/graph3">Tonnage collecté par type de déchets</Link>
            </li>
           <li>
             <Link to="/graph4">Poids des déchets collectés par habitant </Link>
            </li>
        	</ul> 

          <div id="chartdiv" style={{ width: '800px', height: '600px', margin: '20px auto'}}></div>
          
          </div>
        );
      }
    }

export default DechetsPap_tot;






