import React, { Component } from 'react';


import * as am4core from '@amcharts/amcharts4/core';
import '@amcharts/amcharts4/charts';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4editor from '@amcharts/editor4';
import {BrowserRouter as Router,Link} from "react-router-dom";

am4core.useTheme(am4themes_animated);

class Dechets extends Component <any, any> {
  
  private chart?: am4core.Sprite;
  private launcher?: am4editor.EditorLauncher;
  private chartConfiguration : object;

  

  constructor(props: any) {
    super(props);
    // this.editChart = this.editChart.bind(this);
    this.state = {
      listedechets: []
  };
  this.getDataDechets = this.getDataDechets.bind(this);
  }

  getDataDechets(){
    fetch('https://data-api.pevelecarembault.fr/tonnage_dechetterie')
    .then(res => {
      if(res.ok) return res.json();
      throw new Error ('Request failed');
    })
    .then(data => {
      this.setState({listedechets : data})
    })
    .catch(error => {
      console.log(error);
    })
  }

  componentDidMount() {
    this.getDataDechets()
    console.log(this.state.listedechets)

  }

  configGraph(){
  let  iconPath = "M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z"
  let chart = am4core.create("chartdiv", am4charts.SlicedChart);
  chart.hiddenState.properties.opacity = 0;
  chart.data = this.state.listedechets;
  chart.numberFormatter.numberFormat = "#.#";
  let series = chart.series.push(new am4charts.PictorialStackedSeries());
  series.dataFields.value = "tot_decht";
  series.dataFields.category = "type";
  series.alignLabels = true;
  series.startLocation = 0.2;
  series.endLocation = 1;
  series.slicesContainer.background.fill = am4core.color("#AEAFAF");
  series.maskSprite.path = iconPath;
  series.ticks.template.locationX = 1;
  series.ticks.template.locationY = 0.5;
  series.labels.template.text = "{type}: [bold]{value}[/] tonnes ({value.percent}%) ";
  series.slices.template.tooltipText = "{type}: [bold]{value}[/] tonnes";
  series.labelsContainer.width = 200;
  series.slices.template.propertyFields.fill = "color";
  series.slices.template.propertyFields.stroke = "color";
  chart.legend = new am4charts.Legend();
  chart.legend.position = "bottom";
  chart.legend.valign = "bottom";
  chart.legend.reverseOrder = true;
  chart.exporting.menu = new am4core.ExportMenu();
  let titles1 = chart.titles.push(new am4core.Label());
  titles1.text = "Tonnage collecté par type de déchets";
  titles1.paddingBottom = 20;
  titles1.fontFamily = "HELVETICA";
  titles1.fontSize = 22;
  titles1.fontWeight = "bold";

    }

 componentWillUnmount() {
   if (this.chart) {
     this.chart.dispose();
   }
  }


  renderChart() {
     if (this.chart) {
      this.chart.dispose();
     }
     this. configGraph();
    // this.chart = am4core.createFromConfig((this.configGraph()), 
    //  'chartdiv'
    // );
  }


  render() {
    this.renderChart();
    return (
      <div>
       <h1>Chiffres clefs Pévéle Carembault</h1>
      	<h2> Déchetteries: </h2>
         <ul>
            <li>
             <Link to="/">Tonnage collecté par type de déchets</Link>
            </li>
           <li>
             <Link to="/graph1">Poids des déchets collectés par habitant </Link>
            </li>
           <li>
             <Link to="/graph2">Tonnage collecté par mois</Link>
           </li>
        	</ul>  
      <h2> Collecte en porte à porte: </h2>
         <ul>
            <li>
             <Link to="/graph3">Tonnage collecté par type de déchets</Link>
            </li>
           <li>
             <Link to="/graph4">Poids des déchets collectés par habitant </Link>
            </li>
        	</ul> 

        <div id="chartdiv" style={{ width: '800px', height: '600px', margin: '20px auto'}}></div>
        <p>Pour filtrer une ou plusieurs catégories, cliquez sur les icones dans la légende afin de les activer/désactiver. Passez votre souris sur le graphe pour visualiser d'autres informations</p>

      </div>
    );
  }
}

export default Dechets;


