import React, { Component } from 'react';


import * as am4core from '@amcharts/amcharts4/core';
import '@amcharts/amcharts4/charts';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4editor from '@amcharts/editor4';
import {BrowserRouter as Router,Link} from "react-router-dom";

am4core.useTheme(am4themes_animated);

class Dechets extends Component <any, any> {
  
  private chart?: am4core.Sprite;
  private launcher?: am4editor.EditorLauncher;
  private chartConfiguration : object;

  

  constructor(props: any) {
    super(props);
    // this.editChart = this.editChart.bind(this);
    this.state = {
      listedechets: []
  };
  this.getDataDechets = this.getDataDechets.bind(this);
  }

  getDataDechets(){
    fetch('https://data-api.pevelecarembault.fr/evol_pap_hab')
    .then(res => {
      if(res.ok) return res.json();
      throw new Error ('Request failed');
    })
    .then(data => {
      this.setState({listedechets : data})
    })
    .catch(error => {
      console.log(error);
    })
  }

  componentDidMount() {
    this.getDataDechets()
    console.log(this.state.listedechets)

  }

  configGraph(){
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.data = this.state.listedechets;
    chart.numberFormatter.numberFormat = "#.#";
    let title1 = chart.titles.create();
    title1.text = "Kg/habiant/an";
    title1.fontFamily = "HELVETICA";
    title1.fontSize = 15;
    title1.marginBottom = 30;
    let title = chart.titles.create();
    title.text = "Évolution flux de déchets";
    title.fontFamily = "HELVETICA";
    title.fontWeight = "bold";
    title.fontSize = 20;
    title.marginBottom = 5;

    // Create axes
	  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
	  categoryAxis.dataFields.category = "année";
	  categoryAxis.renderer.grid.template.location = 0;
    
	  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	  //valueAxis.renderer.inside = true;
	  //valueAxis.renderer.labels.template.disabled = true;
	  valueAxis.min = 0;
    
	  // Create series
	  function createSeries(field, name) {
    
	  // Set up series
	  let series = chart.series.push(new am4charts.ColumnSeries());
	  series.name = name;
	  series.dataFields.valueY = field;
	  series.dataFields.categoryX = "année";
	  series.sequencedInterpolation = true;
	  //series.cursorTooltipEnabled = false;
    
	  // Make it stacked
	  series.stacked = true;
    
	  // Configure columns
	  series.columns.template.width = am4core.percent(60);
	  series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
    
	  // Configure legend
	  series.legendSettings.valueText = "{valueY.close}kg/hab";
	  series.legendSettings.itemValueText = "{valueY}kg/hab";
    
	  // Add label
	  let labelBullet = series.bullets.push(new am4charts.LabelBullet());
	  labelBullet.label.text = "{valueY}";
	  labelBullet.locationY = 0.5;
	  labelBullet.label.fill = am4core.color("#fff");
    
	  return series;
	  }
  
	  createSeries('ordures ménagères', 'ordures ménagères');
	  createSeries("recyclables hors verre", "recyclables hors verre");
	  createSeries("verre (bornes à verre)", "verre (bornes à verre)");
	  createSeries("biodéchets", "biodéchets");
	  createSeries("encombrants", "encombrants");

	  // Legend
	  chart.legend = new am4charts.Legend();
  
	  // Cursor
	  chart.cursor = new am4charts.XYCursor();
	  chart.cursor.behavior = "none";
	  //exporting
	  chart.exporting.menu = new am4core.ExportMenu();
	  chart.exporting.menu.align = "left";
	  chart.exporting.menu.verticalAlign = "top";

    }

 componentWillUnmount() {
   if (this.chart) {
     this.chart.dispose();
   }
  }


  renderChart() {
    if (this.chart) {
     this.chart.dispose();
    }
    this. configGraph();
   // this.chart = am4core.createFromConfig((this.configGraph()), 
   //  'chartdiv'
   // );
 }


 render() {
  this.renderChart();
  return (
    <div>
     <h1>Chiffres clefs Pévéle Carembault</h1>
      <h2> Déchetteries: </h2>
       <ul>
       <li>
          <Link to="/">Tonnage collecté par type de déchets</Link>
        </li>
        <li>
          <Link to="/graph1">Poids des déchets collectés par habitant </Link>
        </li>
        <li>
          <Link to="/graph2">Tonnage collecté par mois</Link>
        </li>
        </ul>
        <h2> Collecte en porte à porte: </h2>
         <ul>
            <li>
             <Link to="/graph3">Tonnage collecté par type de déchets</Link>
            </li>
           <li>
             <Link to="/graph4">Poids des déchets collectés par habitant </Link>
            </li>
        	</ul> 

       <div id="chartdiv" style={{ width: '800px', height: '600px', margin: '20px auto'}}></div>
        <p>Pour filtrer une ou plusieurs catégories, cliquez sur les icones dans la légende afin de les activer/désactiver. Passez votre souris sur le graphe pour visualiser d'autres informations</p>

    </div>
  );
  }
}

export default Dechets;
