import React, { Component } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Dechets from './components/Dechets/Dechets';
import DechetsThumeries from './components/DechetsThumeries/DechetsThumeries';
import DechetsGenech from './components/DechetsGenech/DechetsGenech';
import DechetsOrchies from './components/DechetsOrchies/DechetsOrchies';
import Dechetspap_hab from './components/DechetsPap_hab/DechetsPap_hab'
import DechetsPap_tot from './components/DechetsPap_tot/DechetsPap_tot';


class App extends Component <any, any> {

  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Dechets/>} />
          <Route path="/graph1" element={<DechetsThumeries/>} />
          <Route path="/graph2" element={<DechetsOrchies/>} />
	        <Route path="/graph4" element={<Dechetspap_hab/>} />
	        <Route path="/graph3" element={<DechetsPap_tot/>} />
        {/* <button onClick={this.editChart}>edit chart</button> */}
      </Routes>
      </div>
    );
  }
}

export default App;


