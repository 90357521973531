import React, { Component } from 'react';


import * as am4core from '@amcharts/amcharts4/core';
// import * as am4charts from '@amcharts/amcharts4/charts';
import '@amcharts/amcharts4/charts';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4editor from '@amcharts/editor4';
import {BrowserRouter as Router,Link} from "react-router-dom";

am4core.useTheme(am4themes_animated);

class DechetsThumeries extends Component <any, any> {
  
  private chart?: am4core.Sprite;
  private launcher?: am4editor.EditorLauncher;
  private chartConfiguration : object;


  

  constructor(props: any) {
    super(props);
    // this.editChart = this.editChart.bind(this);
    this.state = {
      listedechets: []
  };
  this.getDataDechets = this.getDataDechets.bind(this);
  }

  getDataDechets(){
    fetch('https://data-api.pevelecarembault.fr/kg_hab_dechetterie')
    .then(res => {
      if(res.ok) return res.json();
      throw new Error ('Request failed');
    })
    .then(data => {
      this.setState({listedechets : data})
    })
    .catch(error => {
      console.log(error);
    })
  }

  componentDidMount() {
    this.getDataDechets()
    console.log(this.state.listedechets)

  }

  configGraph(){
    this.chartConfiguration = {
        "type": "PieChart",
        "data": this.state.listedechets,
        "series": [{
          "type": "PieSeries",
          "ticks": {
              "template": {
                  "type": "PieTick",
                  "disabled": true,
                  "fill": "#c51616",
                  "strokeWidth": 1
              }
          },
          "slices": {
              "template": {
                  "type": "Slice",
                  "propertyFields": {
                      "fill": "color"
                  },
                  "stroke": "#ffffff",
                  "tooltipText": "{type} :[bold] {kg_hab_annee} kg/habitant/an [/]",
                  "tooltipPosition": "pointer"
              }
          },
          "labels": {
              "template": {
                  "type": "AxisLabelCircular",
                  "text": "{value}",
                  "paddingLeft": 0,
                  "paddingTop": 0,
                  "paddingRight": 0,
                  "paddingBottom": 0,
                  "scale": 1,
                  "radius": -30,
                  "locationOnPath": 0.5,
                  "location": 0.5,
                  "inside": false,
                  "bent": true,
                  "fill": "#ffffff",
                  "fontFamily": "helvetica",
                  "fontSize": 14,
                  "fontWeight": "normal"
              }
          },
          "dataFields": {
              "value": "kg_hab_annee",
              "category": "type"
          },
          "bulletsContainer": {
          "children": [{
          "type": "Label",
          "forceCreate": true,
          "text": "{values.value.sum} kg/habitant",
          "horizontalCenter" : "middle",
          "verticalCenter": "bottom",
          "fontFamily": "HELVETICA",
          "fontSize": 25,
          "fontWeight": "bold",
          "numberFormat": "#",
          "intlLocales": "fr-FR"
                }]
           },
          "simplifiedProcessing": false,
          "calculatePercent": true,
          "maxHeight": 250,
          "endAngle": 270,
          "alignLabels": false,
          "fontFamily": "HELVETICA",
          "fontSize": 12,
          "fontWeight": "bold",
          "colors": {
              "type": "ColorSet"
          }
      }],
      "titles": {
          "values": [{
              "type": "Label",
              "text": "( kg/hab/année)",
              "textAlign": "start",
              "x": 0,
              "reverseOrder": false
          }, {
              "type": "Label",
              "id": "titre",
              "text": "Poids des déchets collectés par habitant",
              "paddingTop": 15,
              "paddingBottom": "",
              "fontFamily": "HELVETICA",
              "fontSize": 20,
              "fontWeight": "bold"
          }],
          "template": {
              "type": "Label"
          }
      },
      "legend": {
          "type": "Legend",
          "reverseOrder": true,
          "valueLabels": {
              "template": {
                  "type": "Label",
                  "showOnInit": false,
                  "visible": false,
                  "hidden": false
              }
          },
          "markers": {
              "template": {
                  "type": "Container",
                  "maxWidth": 13,
                  "maxHeight": 10
              }
          },
          "paddingTop": 0,
          "align": "center",
          "maxWidth": 50,
          "maxHeight": 100,
          "position": "absolute",
          "fontFamily": "HELVETICA",
          "fontSize": 10,
          "fontWeight": "bolder"
      },
      "exporting": {
          "menu": {
          "align": "left",
           "verticalAlign": "top"
       }
      },
      "radius": "60%",
      "innerRadius": "40%"

      };
      return this.chartConfiguration

    }

 componentWillUnmount() {
   if (this.chart) {
     this.chart.dispose();
   }
  }


  renderChart() {
     if (this.chart) {
      this.chart.dispose();
     }
    this.chart = am4core.createFromConfig((this.configGraph()), 
     'chartdiv'
    );
  }

   



  render() {    
    this.renderChart();
    return (
      <div>
        <h1>Chiffres clefs Pévèle Carembault</h1>
        <ul>
           <li>
            <Link to="/">Tonnage collecté par type de déchets</Link>
          </li>
          <li>
            <Link to="/graph1">Poids des déchets collectés par habitant </Link>
          </li>
          <li>
            <Link to="/graph2">Tonnage collecté par mois</Link>
          </li>
        </ul>
        <h2> Collecte en porte à porte: </h2>
         <ul>
            <li>
             <Link to="/graph3">Tonnage collecté par type de déchets</Link>
            </li>
           <li>
             <Link to="/graph4">Poids des déchets collectés par habitant </Link>
            </li>
        	</ul> 
        <div id="chartdiv" style={{ width: '800px', height: '600px', margin: '20px auto'}}></div>
        <p>Pour filtrer une ou plusieurs catégories, cliquez sur les icones dans la légende afin de les activer/désactiver. Passez votre souris sur le graphe pour visualiser d'autres informations</p>
      </div>
    );
  }
}

export default DechetsThumeries;





